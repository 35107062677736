import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import Banner from './banner';
import { StyledA } from './styled-link';
import { LayoutRow, MainColumn } from '../utils/layout-row';
import { rhythm } from '../utils/typography';

const ProjectRow = styled(LayoutRow)`
    color: ${props => props.isBannerDark ? '#fff' : 'unset'};
`;

const ProjectDetails = styled(MainColumn)`
    min-height: 360px;
    padding: 50px 0 30px;
`;

const ProjectTitle = styled.h2`
    margin-bottom: 0;
`;

const ProjectSummary = styled.p`
    margin-bottom: ${rhythm(2)};
`;

const Project = ({ company, date, summary, outputs, banner }) => {
    const projectMoment = moment(date);
    return (
        <ProjectRow as="section" isBannerDark={banner.isDark} fit>
            <Banner banner={banner} />
            <ProjectDetails>
                <ProjectTitle>{company}</ProjectTitle>
                <p>
                    <time dateTime={projectMoment.format('YYYY-MM-DD')}>
                    {projectMoment.format('YYYY MMM')}
                    </time>
                </p>
                <ProjectSummary>{summary}</ProjectSummary>
                <p>
                {
                    outputs.map(output => (
                        output.link
                        ? <React.Fragment key={output.label}><StyledA
                            key={output.link}
                            href={output.link}>{output.label}</StyledA><br/></React.Fragment>
                        : <React.Fragment key={output.label}>{output.label}<br/></React.Fragment>
                    ))
                }
                </p>
            </ProjectDetails>
        </ProjectRow>
    );
}

export default Project;