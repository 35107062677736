import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { LayoutRow, MainColumn } from '../utils/layout-row';
import Section from './section';
import Markdown from './markdown';
import Project from './project';
import {} from './banner';

const ProjectsContent = ({ introNode, projectEdges }) => (
    <Section>
        <LayoutRow>
            <MainColumn>
                <Section>
                    <h1>{introNode.frontmatter.title}</h1>
                    <Markdown html={introNode.html}/>
                </Section>
            </MainColumn>
        </LayoutRow>
        {
            projectEdges
                .map((e, i) =>
                    <Project
                        key={`${e.node.frontmatter.company}.${e.node.frontmatter.title}`}
                        company={e.node.frontmatter.company}
                        date={e.node.frontmatter.date}
                        summary={e.node.frontmatter.summary}
                        outputs={e.node.frontmatter.outputs}
                        banner={e.node.frontmatter.banner}/>
                )
        }
    </Section>
);

const Projects = () => (
    <StaticQuery
        query={graphql`
            query {
                intro: allMarkdownRemark(
                    filter: {
                        frontmatter: {
                            type: { eq: "projects" }
                        }
                    }
                ) {
                    edges {
                        node {
                            html
                            frontmatter {
                                title
                            }
                        }
                    }
                }

                projects: allMarkdownRemark(
                    filter: {
                        frontmatter: {
                            type: { eq: "project" }
                            published: { ne: false }
                        }
                    },
                    sort: {
                        fields: [frontmatter___date]
                        order: DESC
                    }
                ) {
                    edges {
                        node {
                            frontmatter {
                                company
                                date
                                summary
                                outputs {
                                    label
                                    link
                                }
                                banner {
                                    ...Banner
                                }
                                services
                            }
                        }
                    }
                }
            }
        `}

        render={data => ProjectsContent(
            {
                introNode: data.intro.edges[0].node,
                projectEdges: data.projects.edges
            }
        )}
    />
);

export default Projects; 